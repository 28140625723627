<template>
  <div>
    <div
      class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
    >
      <h5 class="mb-0">
        <b>
          {{ selectedItem.is_copy ? "COPY " : "ADD " }}{{ GroupName }} Group
        </b>
      </h5>

      <feather-icon
        class="ml-1 cursor-pointer"
        icon="XIcon"
        size="16"
        @click="closeSidebar"
      />
    </div>
    <b-form class="p-2" @submit.prevent="submitForm">
      <b-form-group :label="`${GroupName} Group Name`" label-for="lbl_name">
        <b-form-input
          v-model="form.lbl_name"
          id="lbl_name"
          :placeholder="`${GroupName} Group Name`"
        />
      </b-form-group>

      <b-form-group :label="GroupName">
        <v-select
          :disabled="disabled"
          :placeholder="GroupName"
          label="lbl_name"
          :options="group_val_options"
          :reduce="(label) => label.lbl_id"
          multiple
          :close-on-select="false"
          v-model="form.group_val_ids"
        ></v-select>
      </b-form-group>

      <b-form-group label="Sort order" label-for="order">
        <b-form-input
          v-model="form.order"
          id="order"
          type="number"
          placeholder="Sort order"
        />
      </b-form-group>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        variant="primary"
        class="mb-1 mt-3"
        block
      >
        Submit
      </b-button>
    </b-form>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";
import * as MasterServices from "@/apiServices/MastersServices";
import { convertToIntArr } from "@/utils/helpers";
import { labels } from "@/utils/constants";
import { capitalize } from "lodash";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    ToastificationContentVue,
    BFormTextarea,
    vSelect,
  },
  directives: {
    Ripple,
  },

  props: {
    masterInfo: Object,
    // masterList: Array,
    selectedItem: Object,
    closeSidebar: Function,
    defaultSortOrder: Number,
  },

  data() {
    return {
      form: {
        lbl_id: "",
        lbl_name: "",
        group_val_ids: [],
        order: "",
        status: true,
      },
      disabled: false,
    };
  },
  computed: {
    GroupName() {
      return capitalize(this.masterInfo.lbl_cat.replace(/_/g, " "));
    },

    group_val_options() {
      const lbl_cat = this.masterInfo.lbl_cat;
      const group_val_options = this.$store.state.master[lbl_cat].filter(
        (i) => !i.lbl_is_group
      );
      return group_val_options;
    },
  },
  watch: {
    selectedItem(val) {
      if (val.lbl_id) {
        this.form.lbl_id = val.lbl_id;
        this.form = {
          ...this.form,
          ...val,
          status: val.status ? true : false,
        };
        if (val.is_copy) {
          this.form.lbl_id = undefined;
          this.disabled = false;
        } else {
          this.disabled = true;
        }
        this.getGroup(val.lbl_id);
      } else {
        this.disabled = false;
        this.form = {
          lbl_id: "",
          lbl_name: "",
          group_val_ids: [],
          order: "",
          status: true,
        };
      }
    },
    masterInfo(masterInfo) {
      this.$store.dispatch("master/setLabelMaster", {
        key: masterInfo.lbl_cat,
      });
    },
  },
  methods: {
    async getGroup(lbl_id) {
      try {
        const res = await MasterServices.getGroup(lbl_id);
        if (res.data.status) {
          this.form.group_val_ids = convertToIntArr(
            res.data.data.group_val_ids
          );
          console.log(this.form.group_val_ids);
        }
      } catch (error) {
        console.error("Error in getGroup ", error);
      }
    },
    async submitForm() {
      try {
        if (!this.form.lbl_name) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Provide name for Group",
              icon: "EditIcon",
              variant: "primary",
            },
          });
          return;
        }
        if (this.form.group_val_ids.length < 2) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Provide atleat two values in group",
              icon: "EditIcon",
              variant: "primary",
            },
          });
          return;
        }

        const res = await MasterServices.saveGroup({
          lbl_cat: this.masterInfo.lbl_cat,
          ...this.form,
        });

        if (res.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: res.data.message || "Success",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.closeSidebar ? this.closeSidebar() : null;
          this.$emit("refreshList");
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: res.data.message || "Failed",
              icon: "EditIcon",
              variant: "primary",
            },
          });
        }
      } catch (error) {
        console.error("Error in adding group", error);
      }
    },
  },
  beforeMount() {
    this.$store.dispatch("master/setLabelMaster", { key: labels.territory });
  },
};
</script>
