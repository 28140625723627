<template>
  <div>
    <div
      class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
    >
      <h5 class="mb-0">
        <b>
          {{
            selectedItem.is_copy
              ? "COPY "
              : selectedItem.lbl_id
              ? "UPDATE "
              : "ADD "
          }}{{ masterInfo.lbl_cat.toUpperCase().replace(/_/g, " ") }}</b
        >
      </h5>

      <feather-icon
        class="ml-1 cursor-pointer"
        icon="XIcon"
        size="16"
        @click="closeSidebar"
      />
    </div>
    <b-form class="p-2" @submit.prevent="submitForm">
      <b-form-group
        v-if="masterInfo && masterInfo.lbl_name"
        :label="masterInfo.lbl_name"
        label-for="lbl_name"
      >
        <b-form-input
          :disabled="selectedItem.lbl_id"
          ref="focusMe"
          v-model="form.lbl_name"
          id="lbl_name"
          :placeholder="masterInfo.lbl_name"
        />
      </b-form-group>

      <b-form-group
        v-if="masterInfo && masterInfo.lbl_value1"
        :label="masterInfo.lbl_value1"
        label-for="lbl_value1"
      >
        <v-select
          v-if="masterInfo.lbl_value1_input_type == 'select'"
          v-model="form.lbl_value1"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="masterInfo.lbl_value1_input_options"
          label="lbl_name"
          value="lbl_id"
          placeholder="Please Select"
        />
        <b-form-input
          v-else
          v-model="form.lbl_value1"
          id="lbl_value1"
          :placeholder="masterInfo.lbl_value1"
        />
      </b-form-group>

      <b-form-group
        v-if="masterInfo && masterInfo.lbl_value2"
        :label="masterInfo.lbl_value2"
        label-for="lbl_value2"
      >
        <b-form-textarea
          v-if="masterInfo.lbl_value2_input_type == 'textarea'"
          id="lbl_value2"
          size="sm"
          v-model="form.lbl_value2"
        />

        <b-form-input
          v-else
          v-model="form.lbl_value2"
          id="lbl_value2"
          :placeholder="masterInfo.lbl_value2"
        />
      </b-form-group>
      <b-form-group
        v-if="masterInfo && masterInfo.lbl_value3"
        :label="masterInfo.lbl_value3"
        label-for="lbl_value3"
      >
        <b-form-textarea
          v-if="masterInfo.lbl_value3_input_type == 'textarea'"
          id="lbl_value3"
          size="sm"
          v-model="form.lbl_value3"
        />

        <b-form-input
          v-else
          v-model="form.lbl_value3"
          id="lbl_value3"
          :placeholder="masterInfo.lbl_value3"
        />
      </b-form-group>

      <b-form-group
        v-if="selectedItem.lbl_id"
        label="Sort order"
        label-for="order"
      >
        <b-form-input
          v-model="form.order"
          id="order"
          type="number"
          placeholder="Sort order"
        />
      </b-form-group>

      <!-- <b-form-group v-if="masterInfo && masterInfo.status" :label="masterInfo.status" label-for="status">
        <b-form-checkbox v-model="form.status" class="custom-control-primary" id="status" switch :unchecked-value="0">
        </b-form-checkbox>
      </b-form-group> -->

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        variant="primary"
        class="mb-1 mt-3"
        block
      >
        Submit
      </b-button>
    </b-form>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";

import { addData, updateData } from "@/apiServices/MastersServices";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    ToastificationContentVue,
    BFormTextarea,
    vSelect,
  },
  directives: {
    Ripple,
  },

  props: {
    masterInfo: Object,
    selectedItem: Object,
    closeSidebar: Function,
    defaultSortOrder: Number,
  },

  data() {
    return {
      form: {
        lbl_cat: null,
        lbl_id: null,
        lbl_name: null,
        lbl_value1: null,
        lbl_value2: null,
        lbl_value3: null,
        revenue_upload_validation: null,
        status: null,
        order: null,
      },
    };
  },

  watch: {
    selectedItem() {
      if (this.masterInfo && this.selectedItem) {
        this.$nextTick(() => this.$refs.focusMe.$el.focus());
        this.form = {
          ...this.selectedItem,
          status: !this.selectedItem.lbl_id
            ? true
            : this.selectedItem.status == 1
            ? true
            : false,
          lbl_value3: this.masterInfo.hasOwnProperty("lbl_value3")
            ? this.selectedItem.revenue_upload_validation
            : "",
          revenue_upload_validation:
            this.selectedItem.revenue_upload_validation == 1 ? true : false,
          order:
            this.selectedItem.order && this.selectedItem.order != "999999999"
              ? this.selectedItem.order
              : null,
        };
        if (this.selectedItem.is_copy) {
          this.form.lbl_id = null;
        }
      }
    },
  },

  mounted() {
    // this.$nextTick(() => this.$refs.focusMe.$el.focus());
  },
  methods: {
    async submitForm() {
      let response = null;
      if (!this.form.lbl_id) {
        response = await addData(this.masterInfo.lbl_cat, {
          ...this.form,
          status: this.form.status == true ? 1 : 0,
          revenue_upload_validation: this.masterInfo.hasOwnProperty(
            "lbl_value3"
          )
            ? this.form.lbl_value3
            : this.form.revenue_upload_validation == true
            ? 1
            : 0,
        });
      } else {
        response = await updateData(
          this.masterInfo.lbl_cat,
          {
            ...this.form,
            status: this.form.status == true ? 1 : 0,
            revenue_upload_validation: this.masterInfo.hasOwnProperty(
              "lbl_value3"
            )
              ? this.form.lbl_value3
              : this.form.revenue_upload_validation == true
              ? 1
              : 0,
          },
          this.form.lbl_id
        );
      }

      if (response.data.status) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: response.data.message || "Success",
            icon: "EditIcon",
            variant: "success",
          },
        });
        this.closeSidebar ? this.closeSidebar() : null;
        this.$emit("refreshList");
      } else {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: response.data.message || "Success",
            icon: "EditIcon",
            variant: "failure",
          },
        });
        this.closeSidebar ? this.closeSidebar() : null;
      }
    },
  },
};
</script>
