<template>
  <b-tabs
    vertical
    nav-wrapper-class="sticky-top col-md-3 col-12"
    nav-class="nav-left tabs-style rounded-lg"
    pills
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    v-model="tabIndex"
  >
    <b-tab :key="`search`" title-link-class="p-0 overflow-hidden rounded-md">
      <template #title>
        <div style="height: 100%; width: 100%">
          <b-form-input
            class="rounded-0 border-0"
            @input="onSearch"
            placeholder="Search..."
          ></b-form-input>
        </div>
      </template>
    </b-tab>

    <b-tab
      v-for="(master, index) in mastersList"
      :key="`tab${index}`"
      title-link-class="border-0 shadow-sm"
    >
      <template #title>
        <feather-icon :icon="master.icon || 'HomeIcon'" />
        <span>{{ master.title }}</span>
      </template>
    </b-tab>

    <!-- table -->
    <master-table :masterInfo="masterInfo" />
  </b-tabs>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
} from "bootstrap-vue";
import vSelect from "vue-select";
import MasterTable from "./MasterTable.vue";
import { masters } from "./masterHelpers";

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardHeader,
    BCardBody,
    vSelect,
    MasterTable,
  },
  data() {
    return {
      search: "",
      tabIndex: 1,
      movieInfoDebouce: null,
      masterInfo: masters[0],
      mastersList: masters,
      debounce: null,
    };
  },
  methods: {
    onSearch(input) {
      if (this.debounce) clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        if (input) {
          this.mastersList = masters.filter((item) => {
            if (!item.lbl_name) return;
            const lbl_name = item.lbl_name.toLowerCase();
            const search = input.toLowerCase();
            return lbl_name.includes(search);
          });
        } else {
          this.mastersList = masters;
        }
      }, 1000);
    },
  },
  watch: {
    tabIndex(newValue, oldValue) {
      if (newValue === 0) return;
      this.masterInfo = this.mastersList[newValue - 1];
    },
  },
};
</script>
<style>
/* ::-webkit-scrollbar {
  width: 8px !important;
  background-color: #1f307a;
  border-radius: 5px;
} */
/* ::-webkit-scrollbar:hover {
  background-color: #1f307a;
  border-radius: 5px;
} */
.tabs-style {
  background-color: #f3f3f3;
  padding: 5px;
}
</style>
